import React, { useState } from "react";
import { Variants, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { FADE_DOWN_ANIMATION_VARIANTS } from "../../lib/framer";

const fadeInAnimationVariants = {
  hidden: {
    opacity: 0,

    y: -20,
  },
  show: (index) => ({
    opacity: 1,

    y: 0,

    transition: {
      duration: 0.8,
      delay: 0.15 * index,
      type: "spring", // Use a spring transition for a bounce effect
      stiffness: 120, // Adjust stiffness for the bounce
      damping: 10, //
    },
  }),
};

const Accordion = (index) => {
  const { ref, inView } = useInView({
    triggerOnce: false,
  });
  return (
    <>
      <section
        id="Faqs"
        className=" overflow-x-hidden overflow-y-hidden relative z-10 pb-[4rem]  pt-[4rem]   "
      >
        <motion.div
          ref={ref}
          initial="hidden"
          animate={"show"}
          viewport={{ once: true }}
          variants={{
            hidden: {},
            show: {
              transition: {
                staggerChildren: 0.5,
              },
            },
          }}
          className="flex flex-col items-center justify-center "
        >
          <div>
            <motion.h1
              variants={FADE_DOWN_ANIMATION_VARIANTS}
              className="mb-2 pt-[2rem] block text-[2.5rem] text2 font-bold text-heading-color dark:text-gray-300 relative z-10 text-center"
            >
              FAQ<span className="text-brand">&apos;s</span>
            </motion.h1>
            <motion.h1
              variants={FADE_DOWN_ANIMATION_VARIANTS}
              className=" text-[1.13rem] p-2 text-center"
            >
              Your Guide to Getting Answers Quickly
            </motion.h1>
          </div>

          <motion.div
            ref={ref}
            key={`${index}`} // Concatenate index with unique identifier
            variants={fadeInAnimationVariants}
            initial="hidden"
            animate={inView ? "show" : "hidden"}
            custom={index}
            whileInView="show"
            className="relative z-10 grid grid-cols-1 mt-20 duration-300 ease-in-out md:gap-5 md:gap-x-20 md:grid-cols-2"
          >
            <div>
             
              <AccordionItem
                header="What is Digifolio?"
                text="Digifolio is a blockchain company offering various crypto projects centered around its token, Digitoken. It provides trading pools, a web3 wallet, AI bot, and crypto cards for seamless transactions and financial empowerment in the decentralized landscape."
              />
               <AccordionItem
                header="What is Digitoken?"
                text={
                  <div>
                    Digitoken is a utility token with a total supply of 500
                    million, essential for accessing services within the
                    Digifolio ecosystem. Early investors receive dividends,
                    enhancing its value.
                    
                    <ol>
                      <br/>
                      <li>Total Supply: 500 million tokens</li>
                      <li>Circulation: Currently 300 million tokens</li>
                    </ol>
                  </div>
                }
              />
              <AccordionItem
                header="What projects belong to DigiToken?"
                text={
                  <div>
                    Digifolio's ecosystem encompasses a diverse array of
                    projects leveraging the utility and functionality of
                    DigiToken:
                    <ul>
                    <br/>

                      <li>1. Web3 Wallet</li>
                      <li>2. Digiswap</li>
                      <li>3. Pro Chess Platform</li>
                      <li>4. AI-Powered Trade Bot</li>
                    </ul>
                  </div>
                }
              />

              <AccordionItem
                header="Why buy Digitoken?"
                text={
                  <div>
                    Investing in DigiToken presents several compelling reasons:
                    <ul>
                    <br/>

                      <li>1. Utility Across Projects</li>
                      <li>2. Early Investor Dividends</li>
                      <li>3. Growing Ecosystem</li>
                      <li>4. Innovation and Technology</li>
                      <li>5. Community and Support</li>
                    </ul>
                  </div>
                }
              />
            </div>
            <div>
              <AccordionItem
                header="How to buy Digitoken?"
                text={
                  <ul className="w-full mr-40 md:mr-56">
                    <li>1. Open Presale Page.</li>
                    <li>2. Connect Wallet.</li>
                    <li>3. Select Payment Mode.</li>
                    <li>4. Enter Amount.</li>
                    <li>5. Click 'Buy Now'.</li>
                    <li>6. Approve Transaction.</li>
                    <li>7. Confirmation.</li>
                  </ul>
                }
              />
              <AccordionItem
                header="When will Digitoken list on Exchanges?"
                text="It is expected as per the roadmap. Please keep in touch with Digifolio's social profiles like Twitter and Telegram for further updates."
              />
              <AccordionItem
                header="Will you burn the token?"
                text="Yes, as part of our strategic plan, we will burn Digitoken. This process helps manage token supply and potentially increases its value over time."
              />
              <AccordionItem
                header="Is the Digitoken minable?"
                text="No, Digitoken cannot be mined. It follows a fixed supply model, and tokens are distributed through presales, token sales, and other methods."
              />
            </div>
          </motion.div>
        </motion.div>
      </section>
    </>
  );
};

export default Accordion;

const AccordionItem = ({ header, text }) => {
  const [active, setActive] = useState(false);

  const handleToggle = (event) => {
    event.preventDefault();
    setActive(!active);
  };
  return (
    <div className="mb-8 md:w-[428px] w-[300px] rounded-xl   hover:ease-in duration-1000 ease-in transition transform  border-2 border-gray-400 hover:border-brand dark:border-[0.1px] relative z-10">
      <div className="flex flex-col items-center justify-center p-3">
        <button
          className={`flex items-center justify-between w-full ${
            active
              ? "dark:text-brand-color text-brand-color stroke-brand-color fill-brand-color"
              : "text-heading-color dark:text-gray-300 dark:fill-white dark:stroke-white"
          }`}
          onClick={(e) => handleToggle(e)}
        >
          <div>
            <h4 className="mt-1 text-[1rem] md:text-[1.1rem] font-semibold  text-start max-w-[600px]">
              {header}
            </h4>
          </div>
          <div className="mr-5 flex h-7 w-full max-w-[30px] items-center justify-center rounded-lg">
            <svg
              className={` size-5 duration-100 ease-in ${
                active ? "rotate-45" : ""
              }`}
              viewBox="0 0 20 20"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </button>

        <motion.div
          initial="hidden"
          animate={active ? "show" : "hidden"}
          variants={{
            hidden: { opacity: 0, height: 0 },
            show: { opacity: 1, height: "auto" },
          }}
          transition={{ duration: 0.3 }}
          className={`mt-3 ${active ? "block" : "hidden"}`}
        >
          <p
            className={`py-3 text-[0.9rem] text-start leading-relaxed text-para-color`}
          >
            {text}
          </p>
        </motion.div>
      </div>
    </div>
  );
};
