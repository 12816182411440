import React from "react";
import "./App.css";
import Hero from "./components/landing/Hero";
import About from "./components/landing/About";
import Chart from "./components/landing/Tokenomics";
import Projects from "./components/landing/Projects";
import Roadmap from "./components/landing/Roadmap";
import Security from "./components/landing/Security";
import Partners from "./components/landing/Partners";
import Faqs from "./components/landing/Faqs";
import Footer from "./components/landing/Footer";
import DigiPool from "./components/landing/DigiPool";
import Landing from "./Pages/Landing";
import { Route, Routes } from 'react-router-dom'
import AboutUs from "./Pages/AboutUs";


function App() {
  return (
  
  <Routes>

 
    
    <Route path="/" element={<Landing/>} />
        <Route path="/about-us" element={<AboutUs />} />
    


</Routes>
  );
}

export default App;
