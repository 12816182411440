import React from 'react';
import invest from './../../assets/invest.jpg';
import pool from "./../../assets/pool.png"

function DigiPool() {
  return (
    <div className="mt-[0rem] mb-[-1rem] pb-[1rem] md:pb-[4rem] pt-[1rem] relative bg-cover bg-scroll md:bg-fixed  bg-no-repeat bg-center" style={{backgroundImage: `url(${invest})`}}>
      {/* Background overlay */}
      <div className="absolute inset-0 bg-black opacity-60"></div>
      
      {/* Content */}
      <div className="flex flex-col justify-center items-center gap-[2rem] text-center mt-[3.5rem] mb-8 p-4 relative z-10">
        <h1 className= " text-[2rem] md:text-[3rem] font-bold  text-gray-100">Jump into <span className='text-[#00bc71]'>Digi</span>Pool  Today and Start Earning every week!</h1>
        <p className=" md:px-[20rem] text-[1rem] md:text-[1.2rem]  text-gray-100">
          Investing in DigiPool, managed by <strong className="text-[#00bc71]">Digifolio</strong>, offers an exciting opportunity to participate in crypto trading pools and capitalize on market trends. By joining DigiPool, investors gain access to a professionally managed platform designed to maximize returns while minimizing risks.
        </p>

        <a
          href={"https://digifolio.io/"}
          type="button"
          target="_blank"
          className="text-[#00bc71] text-lg mt-5 md:mt-5 hover:bg-brand font-medium rounded-full px-5 py-2.5 text-center inline-flex items-center border-1 border-[#00bc71] hover:text-white transition duration-150 ease-in-out dark:text-[#00bc71] hover:border-brand  dark:hover:text-white "
        >
          Invest Now
          <svg
            className="w-4 h-4 ms-2"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
        </a>
      </div>
    </div>
  );
}

export default DigiPool;
