import { VerticalTimeline } from "react-vertical-timeline-component";
import TimelineElement from "../ui/TimelineElement";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { FADE_DOWN_ANIMATION_VARIANTS } from "../../lib/framer";

export default function Roadmap() {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  const phases = [
    {
      title: "2024 Q2",
      points: [
        "Launch of DigiPool: Deployment of the primary Investment platform for users. It is a crypto trading pool that investors can participate and earn from the trade pool managed by Digifolio",
        "PreSale Launch: Initial coin offering set for the first week of May 2024 to gather early adopters and investors.",
        "Token Launch: Official launch of the Digitoken following the presale.",
        "Website Launch: Introduction of the official Digitoken website to provide project information, updates, and support.",
      ],
    },
    {
      title: "2024 Q3",
      points: [
        "Marketing Campaign: Aggressive marketing strategies including social media, partnerships, and influencer engagements to boost visibility.",
        "DEX Listing: Listing of Digitoken on decentralized exchanges to enhance liquidity and trading volume.",
        "Exchange Listings: Strategic listings on major cryptocurrency exchanges throughout the year to enhance token accessibility and liquidity.",
        "CoinMarketCap and CoinGecko Listing: Registration on prominent tracking platforms to improve market exposure and credibility.",
        "Community Development: Expansion of the community through online forums, social media, and direct engagement initiatives.",
      ],
    },
    {
      title: "2024 Q4",
      points: [
        "Exchange Listings: Strategic listings on major cryptocurrency exchanges throughout the year to enhance token accessibility and liquidity",
        "Launch of DigiWallet: Release of a secure and user-friendly wallet for storing and managing Digitoken assets.",
        "Digiswap Integration: Implementation of a decentralized exchange feature within DigiWallet for seamless token swaps.",
      ],
    },
    {
      title: "2025 Q1",
      points: [
        "Launch of AI Bot: Introduction of an AI-driven bot to assist users in managing their portfolios and making informed trading decisions.",
      ],
    },
    {
      title: "2025 Q3",
      points: [
        "Launch of DigiPay: A payment gateway for merchants to accept Digitoken as a form of payment, promoting real-world utility.",
      ],
    },
    {
      title: "2025 Q4",
      points: [
        "Launch of Chess Platform: Release of a blockchain-based chess platform allowing players to compete using Digitokens.",
      ],
    },
    {
      title: "Ongoing Initiatives",
      points: [
        "Regular updates: to all platforms to ensure security, user experience, and feature enhancements.",
        "Continuous marketing efforts: to maintain and grow user base and market presence.",
        "Community-driven development: to ensure the project aligns with user needs and industry standards.",
      ],
    },
  ];

  return (
    <>
      <section id="Roadmap">
        <motion.div
          ref={ref}
          initial="hidden"
          animate={inView ? "show" : "hidden"}
          viewport={{ once: true }}
          variants={{
            hidden: {},
            show: {
              transition: {
                staggerChildren: 0.15,
              },
            },
          }}
          className="relative z-10 justify-center gap-0 font-bold text-center md:gap-5 pt-28"
        >
          <motion.h1
            variants={FADE_DOWN_ANIMATION_VARIANTS}
            className="text2 text-[2.2rem] md:text-[2.7rem] font-bold text-center text-heading  dark:text-gray-300 mb-4"
          >
            Roadmap
          </motion.h1>
          <motion.h2
            variants={FADE_DOWN_ANIMATION_VARIANTS}
            className=" text-[1.13rem] text-para dark:text-slate-100 mb-8 p-2 font-normal"
          >
            Explore Six Innovative Projects Where Our Token Takes Center Stage
          </motion.h2>
        </motion.div>

        {/*   1   */}

        <div className="  flex justify-center z-0 absolute  w-[90%] top-[475rem] md:top-[305rem] left-[-2rem] md:left-0">
          <div className=" rounded-full absolute h-[9em] w-[9em]  md:h-[12em] md:w-[12em]"></div>
        </div>

        <div className="  flex justify-center z-0 absolute  w-[30%] top-[455rem]  md:top-[295rem] ml-[5rem] md:ml-[-16rem]">
          <div className="   rounded-full absolute h-[9em] w-[9em]  md:h-[12em] md:w-[12em]"></div>
        </div>

        {/*   2   */}

        <div className="  flex justify-center z-0 absolute  w-[90%] top-[495rem] md:top-[335rem] left-[-2rem] md:left-0">
          <div className=" rounded-full absolute h-[9em] w-[9em]  md:h-[12em] md:w-[12em]"></div>
        </div>

        <div className="  flex justify-center z-0 absolute  w-[30%] top-[525rem]  md:top-[325rem] ml-[5rem] md:ml-[-16rem]">
          <div className="   rounded-full absolute h-[9em] w-[9em]  md:h-[12em] md:w-[12em]"></div>
        </div>

        {/*   3   */}

        <div className="  flex justify-center z-0 absolute  w-[90%] top-[570rem] md:top-[365rem] left-[-2rem] md:left-0">
          <div className=" rounded-full absolute h-[9em] w-[9em]  md:h-[12em] md:w-[12em]"></div>
        </div>

        <div className="  flex justify-center z-0 absolute  w-[30%] top-[545rem]  md:top-[355rem] ml-[5rem] md:ml-[-16rem]">
          <div className="   rounded-full absolute h-[9em] w-[9em]  md:h-[12em] md:w-[12em]"></div>
        </div>

        {/*   4   */}

        <div className="  flex justify-center z-0 absolute  w-[90%] top-[590rem] md:top-[395rem] left-[-2rem] md:left-0">
          <div className=" rounded-full absolute h-[9em] w-[9em]  md:h-[12em] md:w-[12em]"></div>
        </div>

        <div className="  flex justify-center z-0 absolute  w-[30%] top-[620rem]  md:top-[385rem] ml-[5rem] md:ml-[-16rem]">
          <div className="   rounded-full absolute h-[9em] w-[9em]  md:h-[12em] md:w-[12em]"></div>
        </div>

        {/*   5   */}

        <div className="   justify-center z-0 absolute  w-[90%] flex top-[570rem] md:top-[425rem] left-[-2rem] md:left-0">
          <div className=" rounded-full absolute h-[9em] w-[9em]  md:h-[12em] md:w-[12em]"></div>
        </div>

        <div className="  flex justify-center z-0 absolute  w-[30%] top-[640rem]  md:top-[415rem] ml-[5rem] md:ml-[-16rem]">
          <div className="   rounded-full absolute h-[9em] w-[9em]  md:h-[12em] md:w-[12em]"></div>
        </div>

        <section
          className="flex flex-col items-center justify-center w-full gap-10 my-0 scroll-mt-28"
          id="#experience"
        >
          <VerticalTimeline lineColor="#333333">
            {phases.map((phase, index) => {
              return (
                <TimelineElement
                  key={index}
                  title={phase.title}
                  points={phase.points}
                />
              );
            })}
          </VerticalTimeline>
        </section>
      </section>
    </>
  );
}
