import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { FADE_DOWN_ANIMATION_VARIANTS } from "../../lib/framer";
import about1 from "./../../assets/about1.png";
import about2 from "./../../assets/about2.png";
import about3 from "./../../assets/about3.png";
import whyusicon from "./../../assets/aboutus/whyusicon.png"
import stonks from "./../../assets/aboutus/stonks.png"
import { fadeIn } from "../../lib/variants";
const aboutData = [
  {
    image: about1,
    title: "Our Investment Philosophy Unveiled",
    content:
      "We prioritize strategic analysis and diligent research to achieve long-term growth for our investors through blockchain and cryptocurrencies.",
  },
  {
    image: about2,
    title: "At our core: expertise in cryptocurrency.",
    content:
      "Our seasoned team of crypto experts drives our success through in-depth knowledge and innovation.",
  },
  {
    image: about3,
    title: "Transparency and Trust: Our Core Values",
    content:
      "We believe in open communication, honesty, and integrity in all our dealings. It's how we build lasting relationships and earn the trust of our clients and partners.",
  },
];

function About() {
  const { ref, inView } = useInView({
    triggerOnce: false,
  });

  return (
    <div className="flex flex-col pb-32 items-center justify-center h-full pt-[3rem] p-4 md:p-[7.5rem]  ">
      <motion.div
        ref={ref}
        initial="hidden"
        animate={inView ? "show" : "hidden"}
        viewport={{ once: true }}
        variants={{
          hidden: {},
          show: {
            transition: {
              staggerChildren: 0.5,
            },
          },
        }}
        className="border-2 dark:from-gray-800 dark:to-gray-800
          rounded-[2rem] p-0 md:p-4 pt-10  pb-[3rem] dark:bg-gray-800 boxShadow"
      >
        <motion.h1
          variants={FADE_DOWN_ANIMATION_VARIANTS}
          className="text2 text-[1.6rem] md:text-[2.2rem] font-bold text-center text-heading dark:text-gray-300 "
        >
          Who We Are
        </motion.h1>

        <div className="flex items-center justify-between ">
          <motion.div
             variants={FADE_DOWN_ANIMATION_VARIANTS}
          
            className="w-1/3"
          > <img src={whyusicon} className="" />
          </motion.div>
          <div className="flex flex-col w-2/3 space-y-4">
            <motion.div
            variants={FADE_DOWN_ANIMATION_VARIANTS}>
              <img 
              
              src={stonks}/>
            </motion.div>
            <motion.p  variants={FADE_DOWN_ANIMATION_VARIANTS}>Welcome to DigiFolio, your premier digital portfolio management solution. Our experienced team specializes in crafting diversified portfolios focused on high-growth digital assets like cryptocurrencies and tokenized securities. Using advanced analytics and risk management techniques, we aim to maximize returns while minimizing volatility. With DigiFolio, you gain access to cutting-edge tools and personalized insights to optimize your digital investment journey. Welcome aboard!</motion.p>
            <motion.button variants={FADE_DOWN_ANIMATION_VARIANTS} className="text-white bg-brand/80 hover:bg-brand w-fit px-6 py-1.5 rounded-md ">Learn More</motion.button>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default About;
