import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { FADE_DOWN_ANIMATION_VARIANTS } from "../../lib/framer";
import { useTheme } from "next-themes"; // Import useTheme hook from next-themes
import  partner2  from "./../../assets/partner2.png";
import partnerD2 from "./../../assets/partnerD2.png"
import  partner3  from "./../../assets/partner3.png";
import partnerD3  from "./../../assets/partnerD3.png";
import  partner4  from "./../../assets/partner4.png";
import partnerD4  from "./../../assets/partnerD4.png";
import Marquee from 'react-fast-marquee'
import { FaHandPointLeft } from "react-icons/fa6";
import { GiClick } from "react-icons/gi";
import { NavLink } from "react-router-dom";




export default function Partners() {

  const { theme } = useTheme(); // Access the current theme using useTheme hook


  
  const { ref } = useInView({ threshold: 0.5, triggerOnce: false });

  return (
    <section id="Partners">  
    <motion.div
      ref={ref}
      initial="hidden"
      animate={"show"}
      viewport={{ once: true }}
      variants={{
        hidden: {},
        show: {
          transition: {
            staggerChildren: 0.5,
          },
        },
      }}
      className="flex flex-col items-center justify-center px-6 pt-0"
    >
      <motion.h1
        variants={FADE_DOWN_ANIMATION_VARIANTS}
        className="text2 text-[2.2rem] md:text-[2.5rem] font-bold text-center dark:text-gray-300 text-heading"
      >
        Our Team and Partners
      </motion.h1>
      <motion.h2
        variants={FADE_DOWN_ANIMATION_VARIANTS}
        className="max-w-[890px] mt-3 text-center text-lg dark:text-gray-300 text-para"
      >
       At DigiFolio, our team of industry experts and visionary leaders collaborates with esteemed partner companies to drive innovation and success in the crypto world. Together, we are dedicated to delivering cutting-edge solutions and seamless investment opportunities, shaping the future of digital finance. 
      </motion.h2>
      <motion.div
      variants={FADE_DOWN_ANIMATION_VARIANTS} className=" w-screen md:w-[70%]  gap-10 text-center md:m-auto  pt-[-4rem]" >
      <Marquee autoFill pauseOnHover pauseOnClick className="gap-10 ">
        {/* <a href="https://bscscan.com/" target="_blank">
        <div className="ml-[3rem]">
          <img src={partner1} className="w-300 h-300" alt="BSC Scan" />
        </div>
        </a> */}
        <a href="https://mkncrypto.com/">
        <div className="ml-[3rem] mt-6">
          <img src={theme === 'dark' ? partnerD2 : partner2} className="w-[17rem] " alt="MKNCRYPTO" />
        </div>
        </a>
        <a href="https://www.cyberscope.io/" >
        <div className="ml-[3rem]">
          <img src={theme === 'dark' ? partnerD3 : partner3} className="w-[14rem] " alt="Cyberscope" />
        </div>
        </a>
        <a href="https://www.blockliz.com/" >
        <div className="ml-[3rem]">
          <img src={theme === 'dark' ? partnerD4 : partner4} className="w-[14rem]" alt="Blockliz" />
        </div>
        </a>
        </Marquee>
        <div className="w-full flex items-center justify-center text2"><NavLink to={"/about-us"}>
        <a
              href="https://presale.digifolios.com/" target="_blank"
              className="relative inline-flex items-center justify-center px-[1.8rem] md:px-8 py-0 md:py-3 overflow-hidden font-medium text-brand transition duration-300 ease-out border-2 border-brand rounded-full shadow-md group"
            >
              <span className="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-brand group-hover:translate-x-0 ease">
              <GiClick className="text-2xl"/>
              </span>
              <span className="absolute flex items-center justify-center w-full h-full text-brand transition-all duration-300 transform group-hover:translate-x-full ease font-medium md:font-bold text-[1rem]">
              Click here to know more
              </span>
              <span className="relative invisible font-medium md:font-bold text-[1rem]">Click here to know more</span>
            </a>
        </NavLink></div>

      </motion.div>
    </motion.div>
    </section>
  );
}
