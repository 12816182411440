
import React from "react";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { useInView } from "react-intersection-observer";
import { useTheme } from "next-themes"; // Import useTheme hook from next-themes

export default function TimelineElement({
  title,
  points,
  highlightSubheading,
}) {
  const { theme } = useTheme(); // Access the current theme using useTheme hook

  // Determine the border radius based on the theme
  const bgColor =
    theme === "dark"
      ? "linear-gradient(to top right, #1f2937, #1f2937)"
      : "linear-gradient(to top right, #f9fafb, #d1d5db)";
  const bgBorder = theme === "dark" ? "2px solid #4B5563" : "2px solid #D1D5DB";

  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <div ref={ref} className="vertical-timeline-element">
      <VerticalTimelineElement
        contentStyle={{
          background: bgColor,
          boxShadow: "none",
          border: bgBorder,
          textAlign: "left",
          padding: "1.3rem 2rem",
          borderRadius: "15px", // Set the border radius based on the theme
        }}
        contentArrowStyle={{
          borderRight: "14px solid #307358",
        }}
        icon={<p className="text-center flex justify-center items-center"></p>}
        iconStyle={{
            background: '#307358',
            fontSize: '1rem',
            border: '4px solid #307358',
          }}
        visible={inView}
        iconClassName=""
      >
        <h3 className="text-[1.5rem] font-bold text-heading dark:text-gray-300  text2  mb-4">
          {title}
        </h3>
        <ul className="space-y-2">
          {points.map((point, index) => {
            // Split the point at the colon
            const parts = point.split(":");
            if (parts.length === 2) {
              // If there are two parts, apply the highlight to the first part
              return (
                <p key={index} className="text-sm text2">
                  <span className="text-brand font-bold">{parts[0]}:</span> {parts[1]}
                </p>
              );
            } else {
              // If there's no colon or multiple colons, render the point as it is
              return (
                <p key={index} className="text-sm">
                  {point}
                </p>
              );
            }
          })}
        </ul>
      </VerticalTimelineElement>
    </div>
  );
}
