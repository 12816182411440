import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { FADE_DOWN_ANIMATION_VARIANTS } from "../../lib/framer";
import image1 from "./../../assets/image1.svg";
import image2 from "./../../assets/image2.svg";
import image3 from "./../../assets/image3.svg";
import image4 from "./../../assets/image4.svg";
import image5 from "./../../assets/image5.svg";
import image6 from "./../../assets/image6.svg";

const aboutData = [
  {
    title: "Digifolio portal ",
    content:
      "Our token powers Digifolio, an investment platform offering diverse digital asset opportunities. Unlock exclusive features and curated investments with our token.",
    
    src: image1,
  },
  {
    title: "DigiToken",
    content:
      "DigiToken is our ecosystem's backbone, enabling secure and efficient transactions, including purchases, transfers, and access to DeFi protocols, all with unparalleled flexibility and accessibility.",
  
    src: image2,
  },
  {
    title: "Digi Wallet",
    content:
      "Easily store, send, and receive cryptocurrencies with advanced security features, ensuring a seamless experience for accessing funds and exploring decentralized applications.",

    src: image3,
  },
  {
    title: "Digi Play",
    content:
      "Digi Play offers a dynamic platform for players. Access tournaments, and social gameplay to enhance your experience. Join us and immerse yourself in the timeless game of kings with Digi Play.",

    src: image4,
  },
  {
    title: "Digi Swap",
    content:
      "Digi Swap: Simplifying digital asset exchange with competitive rates and secure transactions. Join us for an intuitive trading experience.",

    src: image5,
  },
  {
    title: "Digi AI",
    content:
      "Revolutionizing crypto trading with advanced AI-powered bots. Access cutting-edge algorithms for precise trades. Join us for smarter trading decisions.",

    src: image6,
  },
];

function About() {
  const { ref, inView } = useInView({
    triggerOnce: false,
  });

  return (
    <div id="Projects" className="flex flex-col items-center justify-center h-full  pt-[3rem] p-4 md:p-[7.5rem] ">
      <motion.div
        ref={ref}
        initial="hidden"
        animate={inView ? "show" : "hidden"}
        viewport={{ once: true }}
        variants={{
          hidden: {},
          show: {
            transition: {
              staggerChildren: 0.2,
            },
          },
        }}
        className="border-2 border-gray-300 dark:border-gray-700 bg-gradient-to-tr from-slate-50 via-gray-300 to-stone-50 dark:from-gray-800 dark:to-gray-800
              rounded-[2rem] dark:bg-gray-800 boxShadow"
      >
       <motion.h1 variants={FADE_DOWN_ANIMATION_VARIANTS}  className="text2 text-[2rem] text-heading dark:text-gray-300 md:text-[2.5rem] font-bold text-center mt-10 ">
          Unlocking Possibilities: Diverse Utility <br /> of Our Token
        </motion.h1>
        <motion.h2 variants={FADE_DOWN_ANIMATION_VARIANTS}  className="px-2 pt-2 text-lg text-center text-para dark:text-gray-300">
          Explore Six Innovative Projects Where Our Token Takes Center Stage
        </motion.h2>

        <motion.div  className="grid grid-cols-1 gap-10  sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 mt-[4rem] mb-10 px-[2rem] ">
          {/* Map over aboutData to create grid items */}
          {aboutData.map((item) => (
            <motion.div key={item.title} variants={FADE_DOWN_ANIMATION_VARIANTS}  className="  border-gray-300 hover:border-brand  dark:hover:border-brand  dark:border-gray-700 border-2 bg-transparent rounded-[2rem] transition duration-200 ease-in-out p-6 boxShadow  ">
              <div className="flex flex-col gap-6 space-x-0  items-center text-center md:items-start md:text-left  ">
              <div className="w-[2.6em] h-[2.6em] rounded-full bg-gray-300 flex justify-center items-center">
                <img src={item.src} className="w-[2em] h-[2em] rounded-full" />
              </div>
                <h3 className="text2 text-heading dark:text-gray-300 text-[1.3rem] font-bold">{item.title}</h3>
              </div>
              <p className="mt-2  text-center md:text-left text1 text-para dark:text-gray-300">{item.content}</p>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>
    </div>
  );
}

export default About;
