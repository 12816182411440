
import React, { useEffect } from "react";

export default function PriceChart() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://public.bnbstatic.com/unpkg/growth-widget/cryptoCurrencyWidget@0.0.9.min.js";
    script.async = true; // Set the script to load asynchronously
    document.body.appendChild(script);
    return () => {
      // Cleanup: Remove the script element when the component unmounts
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array to ensure the effect runs only once

  return (
    <>
    <main className="">

      <div
        className="binance-widget-marquee dark:text-white"
        data-cmc-ids="1,1027,1839,5426,52,3408,74,5805,3890,7083"
        data-theme="dark"
        data-transparent="true"
        data-locale="en"
        ></div>

    
    </main>
 </>
  );
} 