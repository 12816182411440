import React from 'react'
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { FADE_DOWN_ANIMATION_VARIANTS } from "../../lib/framer";
import { useTheme } from "next-themes";
import partner2 from "./../../assets/partner2.png";
import partnerD2 from "./../../assets/partnerD2.png"
import partner3 from "./../../assets/partner3.png";
import partnerD3 from "./../../assets/partnerD3.png";
import partner4 from "./../../assets/partner4.png";
import partnerD4 from "./../../assets/partnerD4.png";

const Partner = () => {
    const { theme } = useTheme()
    const { ref, inView } = useInView({
        triggerOnce: false,
    });
    return (
        <div class="flex items-center justify-center  py-24">
            <motion.div
                ref={ref}
                initial="hidden"
                animate={inView ? "show" : "hidden"}
                viewport={{ once: false }}
                variants={{
                    hidden: {},
                    show: {
                        transition: {
                            staggerChildren: 0.5,
                        },
                    },
                }}
                class="flex flex-col">
                <div class="flex flex-col mt-8">
                    {/* <!-- Meet the Team --> */}
                    <div class="container max-w-7xl px-4">
                        {/* <!-- Section Header --> */}
                        <div class="flex flex-wrap justify-center text-center mb-16">
                            <div class="w-full lg:w-6/12 px-4">
                                {/* <!-- Header --> */}
                                <motion.h1
                                    variants={FADE_DOWN_ANIMATION_VARIANTS}
                                    class="text2 text-4xl font-bold mb-8">
                                    Meet Our partners
                                </motion.h1>

                                {/* <!-- Description --> */}
                                <motion.p
                                    variants={FADE_DOWN_ANIMATION_VARIANTS}
                                    class="text2 text-lg font-light">
                                    Our partnerships drive innovation and success in the crypto world. Together, we deliver value and shape the future. Join us in our collaborative journey
                                </motion.p>
                            </div>
                        </div>
                        {/* 
                <!-- Team Members --> */}
                        <div class="flex flex-wrap">
                            {/* <!-- Member #1 --> */}
                            <motion.div
                                variants={FADE_DOWN_ANIMATION_VARIANTS}
                                className='flex flex-col md:flex-row items-center  ml-[3rem] w-full '>
                                    <motion.div
                                    variants={FADE_DOWN_ANIMATION_VARIANTS}
                                    className="md:w-1/3 mr-4">
                                    <img src={theme === 'dark' ? partnerD2 : partner2} className=" " alt="MKNCRYPTO" />
                                </motion.div>
                                <div className='md:w-2/3 flex flex-col space-y-2'>
                                    <h1 className='text2 text-4xl tracking-wide font-semibold'>MKN Crypto Trading DMCC </h1>
                                    <h2 className='font-medium text-xl'>   Corporate Partner</h2>
                                    <p>As a corporate partner to Digifolio, MKN Crypto Trading DMCC brings its expertise and resources in cryptocurrency trading to the collaborative efforts between the two entities.
                                        MKN Crypto Trading DMCC, as a proprietary trading firm based in Dubai, likely specializes in executing trades using its own capital
                                        and may have extensive experience in analyzing cryptocurrency markets, developing trading strategies, and managing risk.

                                        The partnership between MKN Crypto Trading DMCC and Digifolio may involve various joint initiatives, such as exploring new opportunities in the cryptocurrency space,
                                        developing innovative trading products or services, or expanding into new markets.
                                        With MKN Crypto Trading DMCC's expertise in cryptocurrency trading and Digifolio's strengths in technology and digital innovation,
                                        the partnership aims to drive growth and success in the dynamic and rapidly evolving cryptocurrency market.</p></div>

                                




                            </motion.div>
                            <motion.div
                                variants={FADE_DOWN_ANIMATION_VARIANTS}
                                className='flex mt-24 md:mt-0 flex-col md:flex-row items-center  ml-[3rem] w-full '>
                                <motion.div
                                    variants={FADE_DOWN_ANIMATION_VARIANTS}
                                    className="md:w-1/3 mr-4 pt-12 pb-32 md:pb-0 md:pt-0">
                                    <img src={theme === 'dark' ? partnerD4 : partner4} className=" " alt="MKNCRYPTO" />
                                </motion.div>
                                <div className='md:w-2/3 flex flex-col space-y-2'>
                                    <h1 className='text2 text-4xl tracking-wide font-semibold'>Blockliz </h1>
                                    <h2 className='font-medium text-xl'>   Corporate Partner</h2>
                                    <p>Blockliz, a forefront blockchain solutions company based in Dubai, proudly partners with Digifolio to enhance and expand digital innovation and transformation.
                                        This strategic alliance harnesses the strengths of both organizations, blending Blockliz's advanced blockchain technology with Digifolio's expertise in digital solutions.

                                        As a corporate partner, Blockliz collaborates closely with Digifolio to deliver secure, transparent, and efficient blockchain solutions.
                                        This partnership focuses on developing innovative applications that can transform business operations across various industries.</p></div>






                            </motion.div>

                        </div>
                    </div>
                </div>


            </motion.div>
        </div>
    )
}

export default Partner
