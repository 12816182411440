import Navbar from "./Navbar";
import PriceChart from "./PriceChart";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { FADE_DOWN_ANIMATION_VARIANTS } from "../../lib/framer";
import Splinen from "./Splinen";
import animationvideo from "./../../assets/animationvideo.png";
import Progress from "../raisedbar/Progress";
import { NavLink, useLocation } from "react-router-dom";
// import whitepaperPdf from "../../public/whitepaper.pdf"

function Hero() {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  const location = useLocation();
  return (
    <>
    <div
      id="/"
      className="overflow-hidden bg-gradient-to-tr from-gray-500 via-green-100/50 to-gray-400"
    >
      {/* NAVBAR */}
      <Navbar />

      {/* Content */}
      <motion.div
        ref={ref}
        initial="hidden"
        animate={inView ? "show" : "hidden"}
        viewport={{ once: true }}
        variants={{
          hidden: {},
          show: {
            transition: {
              staggerChildren: 0.15,
            },
          },
        }}
        className="flex flex-col xl:flex-row  gap-10   p-3   md:pb-[4rem] md:px-[6rem] dark:bg-black"
      >
        {/* left */}
        <div className="xl:w-[90rem] flex flex-col xl:pt-[2.5rem] lg:pt-10 justify-center items-center xl:justify-start xl:items-start  py-1">
          {/* text */}
          <div className="">
            <motion.h1
              variants={FADE_DOWN_ANIMATION_VARIANTS}
              className="text2 font-bold text-[2.5rem] md:text-[3.5rem] text-heading dark:text-gray-300 p-4 pb-0 md:p-0 text-center xl:text-start"
            >
              Dive into the Future of Investing with{" "}
              <span className="text-brand">DigiFolio</span>
            </motion.h1>
            <motion.p
              variants={FADE_DOWN_ANIMATION_VARIANTS}
              className="text1 font-normal text-[1rem] mt-6 text-para dark:text-gray-300 p-4 md:p-0 text-center xl:text-start"
            >
              Your gateway to cutting-edge cryptocurrency opportunities. Explore
              innovative investment strategies tailored to your goals and
              experience seamless, user-friendly trading. Join us today and
              embark on a journey to financial success with DigiFolio.
            </motion.p>
          </div>
          {location.pathname !== '/about-us' &&<NavLink to={'/about-us'}><motion.button
          variants={FADE_DOWN_ANIMATION_VARIANTS}
            type="button"
            className=" flex md:hidden px-[1.8rem] md:px-8 py-2.5 md:py-4 text2  text-[1.1rem] border sm:text-sm   md:font-bold   text-brand focus:outline-none bg-transparent rounded-full  border-brand  hover:bg-green-000 hover:border-brand hover:text-brand  focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-800 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-brand dark:hover:border-brand dark:hover:bg-gray-800 transition duration-150 ease-in-out light:hover:bg-gradient-to-tl from-gray-300/80 via-gray-200/80 to-gray-300/80 hover:bg-neutral-100"
          >
            About Us
          </motion.button>
          </NavLink>}
          {/* Buttons */}
          <motion.div
            variants={FADE_DOWN_ANIMATION_VARIANTS}
            className="flex gap-6 mt-10 "
          >  
            <a href={"https://presale.digifolios.com/"} target="_blank"
              type="button"
              className="text-white  text2 bg-brand hover:bg-[#00815F]  border-1 border-brand  font-medium rounded-full text-[1.1rem] px-7 py-2.5 text-center inline-flex items-center dark:bg-brand dark:hover:bg-[#00815F] transition duration-150 ease-in-out"
            >
              Buy $DGFL
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a>
            <a
              type="button"
              href={"../../../whitepaper.pdf"} target='_blank'
              className="text-brand text2  hover:bg-brand  font-medium rounded-full text-[1.1rem] px-5 py-2.5 text-center inline-flex items-center    border-1 border-brand hover:text-white hover:border-brand transition duration-150 ease-in-out light:bg-gradient-to-tl from-gray-300/80 via-gray-200/80 to-gray-300/80  dark:bg-transparent dark:text-gray-400 dark:border-gray-600 dark:hover:text-brand dark:hover:border-brand dark:hover:bg-transparent   "
            >
              Whitepaper
            </a>
          </motion.div>
          {/* box */}
          <motion.div
            variants={FADE_DOWN_ANIMATION_VARIANTS}
            className="flex flex-col md:flex-row tab:flex-row justify-between gap-[1rem] md:gap-[3rem] border-2 dark:border-gray-700  rounded-[2rem] mt-[4.5rem] w-[70%]  md:w-min  p-6 text-gray-200  bg-gradient-to-tr from-slate-50 via-gray-300 to-stone-50 text-center xl:text-start dark:bg-gray-800  dark:from-gray-800 dark:to-gray-800 boxShadow "
          >
            <div className="flex flex-col flex-wrap justify-between md:w-max ">
              <motion.div
                variants={FADE_DOWN_ANIMATION_VARIANTS}
                className="flex flex-col flex-wrap justify-between md:w-max "
              >
                <p className="font-semibold  text-heading dark:text-gray-200   text-[1.5rem] md:text-[1.6rem] ">
                  DIGI Token
                </p>
                <p className="font-normal text-heading/70 dark:text-gray-200 text-[1rem]">
                  Token Name
                </p>
              </motion.div>
            </div>
            <div className="flex flex-col justify-between md:w-max ">
              <motion.div
                variants={FADE_DOWN_ANIMATION_VARIANTS}
                className="flex flex-col justify-between md:w-max "
              >
                <p className="font-semibold  text-heading dark:text-gray-200   text-[1.5rem] md:text-[1.6rem] ">
                  DGFL
                </p>
                <p className="font-normal text-heading/70 dark:text-gray-200 text-[1rem]">
                  Token Symbol
                </p>
              </motion.div>
            </div>
            <div className="flex flex-col justify-between md:w-max ">
              <motion.div
                variants={FADE_DOWN_ANIMATION_VARIANTS}
                className="flex flex-col justify-between md:w-max"
              >
                <p className="font-semibold  text-heading dark:text-gray-200   text-[1.5rem] md:text-[1.6rem] ">
                  8
                </p>
                <p className="font-normal text-heading/70 dark:text-gray-200 text-[1rem]">
                  Decimal
                </p>
              </motion.div>
            </div>
            <div className="flex flex-col justify-between md:w-max ">
              <motion.div
                variants={FADE_DOWN_ANIMATION_VARIANTS}
                className="flex flex-col justify-between md:w-max"
              >
                <a href="https://bscscan.com/address/0x0542b4c905f439a783b4b513b82a7e8c1537ffe1" target="_blank">
                  <p className="font-semibold  text-heading dark:text-gray-200   text-[1.5rem] md:text-[1.6rem] hover:text-brand ">
                    BEP20
                  </p>
                </a>
                <p className="font-normal text-heading/70 dark:text-gray-200">
                  Chain
                </p>
              </motion.div>
            </div>
          </motion.div>
          <motion.div
            variants={FADE_DOWN_ANIMATION_VARIANTS}
            className="w-full max-w-xl pl-2 pr-6"
           >
            <Progress/>
           </motion.div>

         
        </div>

        {/* right/ */}
        <motion.div
          variants={FADE_DOWN_ANIMATION_VARIANTS}
          className="animate-[wiggle_20s_ease-in-out_infinite] "
        >
          <img
            src={animationvideo}
            className="block md:hidden tab:hidden  mt-[0.1rem] mb-[2rem] max-w-[100%]"
            alt=""
          />
        </motion.div>
        <Splinen />

        {/* PRICE CHART */}
        </motion.div>
        <PriceChart />
        </div>

   
      
   </>
  );
}

export default Hero;
