import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { FADE_DOWN_ANIMATION_VARIANTS } from "../../lib/framer";
import cyberscope_1 from "./../../assets/cyberscope_1.gif";

function Security() {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <section id="Security">
      <motion.div
        ref={ref}
        initial="hidden"
        animate={inView ? "show" : "hidden"}
        viewport={{ once: true }}
        variants={{
          hidden: {},
          show: {
            transition: {
              staggerChildren: 0.5,
            },
          },
        }}
        className="flex flex-col md:flex-row-reverse md:gap-[4rem] gap-6 items-center justify-center pt-[5rem] pb-[5rem]"
      >
        <motion.div
          variants={FADE_DOWN_ANIMATION_VARIANTS}
          className=" backdrop:shadow-xl"
        >
          {/* <video
            loop
            muted
            autoPlay
            playsInline
            controls={false}
            preload="auto" 
            className="w-[16em] h-[16em] md:w-[25em] md:h-[25em]  rounded-full"
          >
            <source src={cyberscope_2} type="video/mp4" />
          </video> */}
      <a 
       href={"https://www.cyberscope.io/audits/dgfl"}
      className="cursor-pointer"
       target="_blank">
          <img
            src={cyberscope_1}
            className="w-[16em] h-[16em] md:w-[25em] md:h-[25em]  rounded-full"
            alt=""
            />
            </a>
        </motion.div>
        <motion.div className="relative z-10 flex flex-col gap-5 p-2 mb-32 md:mt-32 ">
          <motion.h1
            variants={FADE_DOWN_ANIMATION_VARIANTS}
            className=" md:text-[2.5rem] text-[2rem] font-bold text-heading-color dark:text-gray-300 md:text-start w-full md:max-w-[550px] text2 text-center"
          >
           Your assets, {" "}
            <strong className="font-bold text-brand">secured</strong>{" "}
          </motion.h1>
          <motion.span
            variants={FADE_DOWN_ANIMATION_VARIANTS}
            className="md:text-start text-para dark:text-gray-300 text-[1rem] md:text-[1.125rem]  w-full md:max-w-[450px] text-center"
          >
            Audited by the world's leading security firm,{" "}
            <strong className="text-brand">Cyberscope</strong> , we're
        pleased to say that our score of
            <strong className="text-brand"> 9.5/10</strong>
            , makes us one of
        the safest projects coming to the market..
          
          </motion.span>
  
          <motion.div
          variants={FADE_DOWN_ANIMATION_VARIANTS}
          className="pt-[0rem] md:pt-[2rem] text-center md:text-left"
        >
          <a
          href={"https://www.cyberscope.io/audits/dgfl"}
            type="button"
            target="_blank"
            className="text-brand text2 mt-10 md:mt-0 hover:bg-brand  font-medium rounded-full text-[1.1rem] px-5 py-2.5 text-center inline-flex items-center border-1 border-brand hover:text-white transition duration-150 ease-in-out light:bg-gradient-to-tl from-gray-300/80 via-gray-200/80 to-gray-300/80 dark:bg-transparent dark:text-brand dark:border-brand dark:hover:text-white dark:hover:border-brand dark:hover:bg-brand  "
          >
            Audit DGFL
            <svg
              className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </a>
        </motion.div>
        </motion.div>
        
      </motion.div>
    </section>
  );
}

export default Security;
