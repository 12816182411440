import Navbar from "../landing/Navbar";
import PriceChart from "../landing/PriceChart";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { FADE_DOWN_ANIMATION_VARIANTS } from "../../lib/framer";
import Splinen from "../landing/Splinen";
import animationvideo from "./../../assets/animationvideo.png";
import Spline from "@splinetool/react-spline";
// import whitepaperPdf from "../../public/whitepaper.pdf"

function Hero() {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <>
    <div
      id="/"
      className="overflow-hidden "
    >
      {/* NAVBAR */}
      <Navbar />

      {/* Content */}
      <motion.div
        ref={ref}
        initial="hidden"
        animate={inView ? "show" : "hidden"}
        viewport={{ once: true }}
        variants={{
          hidden: {},
          show: {
            transition: {
              staggerChildren: 0.15,
            },
          },
        }}
        className="flex 
       relative flex-col xl:flex-row gap-10      items-center justify-center md:pb-[12rem]  md:px-[6rem] dark:bg-black"
      >
          {/* left */}
        <div className=" flex flex-col xl:pt-[2.5rem] justify-center items-center xl:justify-center xl:items-center  py-1">
          {/* text */}
          <div className="w-full">
            <motion.h1
              variants={FADE_DOWN_ANIMATION_VARIANTS}
              className="text2 text-brand  flex flex-col items-center justify-center font-bold text-[2.5rem] md:text-[5.5rem]  dark:text-gray-300 p-4 pb-0 md:p-0 text-center xl:text-start"
            >
             About US
             <span className=" bg-brand/90 px-6 py-1.5 text-small rounded-md text-white">Home / About Us</span>
              <span className=" mt-3 text-base font-thin max-w-4xl text-center px-6 py-2 rounded-md  ">Welcome to DigiFolio, your premier digital portfolio management solution. Our experienced team specializes in crafting diversified portfolios focused on high-growth digital assets like cryptocurrencies and tokenized securities. Using advanced analytics and risk management techniques, we aim to maximize returns while minimizing volatility. With DigiFolio, you gain access to cutting-edge tools and personalized insights to optimize your digital investment journey. Welcome aboard!</span>
            </motion.h1>
            
          </div>
          
         
        </div>

     
       

        {/* PRICE CHART */}
        </motion.div>
   
        </div>

   
      
   </>
  );
}

export default Hero;
