import React from "react";
import { useTheme } from "next-themes"; // Import useTheme hook from next-themes
import ThemeSwitch from "../ui/ThemeSwitch";
import { useLocation } from "react-router-dom";
import logoLight from "./../../assets/logo.png";
import logoDark from "./../../assets/logo-dark.png";
import { NavLink } from "react-router-dom";

export default function Navbar() {
  const location = useLocation();
  const { theme } = useTheme(); // Access the current theme using useTheme hook

  // Determine which logo to use based on the theme
  const logoSrc = theme === "dark" ? logoDark : logoLight;

  return (
    <div className="navbar flex items-center justify-between px-[1rem] md:px-[8rem] py-[1.7rem] gap-5 dark:bg-black">
      {/* Left */}
      <div>
        {/* Render the logo with the determined source */}
        <NavLink to={'/'}><img
          src={logoSrc}
          className="w-[170px] "
          alt="Digifolio"
          loading="eager"
        />
        </NavLink>
      </div>
      {location.pathname !== '/about-us' && (
            <a
              href="https://presale.digifolios.com/" target="_blank"
              className="relative  inline-flex md:hidden items-center justify-center px-[2rem] md:px-8 py-0 md:py-3 overflow-hidden font-medium text-brand transition duration-300 ease-out border-2 border-brand rounded-full shadow-md group"
            >
              <span className="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-brand group-hover:translate-x-0 ease">
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  ></path>
                </svg>
              </span>
              <span className="absolute flex items-center justify-center w-full h-full text-brand transition-all duration-300 transform group-hover:translate-x-full ease font-medium md:font-bold text-[1rem]">
                Buy $DGFL
              </span>
              <span className="relative invisible font-medium md:font-bold text-[0.8rem]">Buy $DGFL</span>
            </a>
          )}
      {/* Right */}
      <div className="flex items-center justify-around gap-4 md:gap-8 ">
        <div className="flex gap-5">
          {/* <button
            type="button"
            className="hidden md:block py-2.5 px-5 me-2 text-[0.65rem] sm:text-sm font-medium text-white focus:outline-none bg-transparent rounded-full border border-gray-200 hover:bg-green-000 hover:border-brand hover:text-brand text2 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-800 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-brand dark:hover:border-brand dark:hover:bg-gray-800 transition duration-150 ease-in-out light:hover:bg-gradient-to-tl from-gray-300/80 via-gray-200/80 to-gray-300/80 hover:bg-neutral-100"
          >
            View Whitepaper
          </button>
          
          <button
            type="button"
            className="block md:hidden py-2.5 px-5 me-2 text-[0.65rem] sm:text-sm font-medium text-white focus:outline-none bg-transparent rounded-full border border-gray-200 hover:bg-green-000 hover:border-brand hover:text-brand text2 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-800 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-brand dark:hover:border-brand dark:hover:bg-gray-800 transition duration-150 ease-in-out light:hover:bg-gradient-to-tl from-gray-300/80 via-gray-200/80 to-gray-300/80 hover:bg-neutral-100"
          >
            Whitepaper
          </button> */}
          {location.pathname !== '/about-us' &&<NavLink to={'/about-us'}><button
            type="button"
            className=" hidden md:flex px-[1.8rem] md:px-8 py-0 md:py-4  text-[1rem] border-2  backdrop-blur-xl shadow-lg sm:text-sm font-medium  md:font-bold   text-brand focus:outline-none bg-transparent rounded-full  border-brand  hover:bg-green-000 hover:border-brand hover:text-brand  focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-800 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-brand dark:hover:border-brand dark:hover:bg-gray-800 transition duration-150 ease-in-out light:hover:bg-gradient-to-tl from-gray-300/80 via-gray-200/80 to-gray-300/80 hover:bg-neutral-100"
          >
            About Us
          </button>
          </NavLink>}

          {location.pathname !== '/about-us' && (
            <a
              href="https://presale.digifolios.com/" target="_blank"
              className="relative hidden md:inline-flex items-center justify-center px-[1.8rem] md:px-8 py-0 md:py-3 overflow-hidden font-medium text-brand transition duration-300 ease-out border-2 border-brand rounded-full shadow-md group"
            >
              <span className="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-brand group-hover:translate-x-0 ease">
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  ></path>
                </svg>
              </span>
              <span className="absolute flex items-center justify-center w-full h-full text-brand transition-all duration-300 transform group-hover:translate-x-full ease font-medium md:font-bold text-[1rem]">
                Buy $DGFL
              </span>
              <span className="relative invisible font-medium md:font-bold text-[1rem]">Buy $DGFL</span>
            </a>
          )}
        </div>

        <div>
          <ThemeSwitch />
        </div>
      </div>
    </div>
  );
}
