import React, { useEffect } from 'react'
import Hero from '../components/aboutus/Hero'
import About from '../components/aboutus/About'
import Teams from '../components/aboutus/Teams'
import Partner from '../components/aboutus/Partner'
import Footer from '../components/landing/Footer'

const AboutUs = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <div className="bg-gradient-to-tl from-gray-500 via-green-100/50 to-gray-400 ">
    
    <Hero/>
    </div>
    {/* <div className="bg-gradient-to-tr from-gray-400 via-green-100/50 to-gray-500 ">
      <div className=" dark:bg-black">
        <About/>
      </div>
    </div> */}
    <div className="bg-gradient-to-tr from-gray-400 via-green-100/50 to-gray-500">
      <div className=" dark:bg-black">
       
        <Teams/>
      </div>
    </div>
    <div className="bg-gradient-to-tl from-gray-500 via-green-100/50 to-gray-400 ">
      <div className=" dark:bg-black">
       
        <Partner/>
      </div>
    </div>
    <div className="bg-gradient-to-tr from-gray-300 via-green-100/50 to-gray-400 ">
      <div className=" dark:bg-black">
    <Footer/>
      </div>
    </div>

    </>
  )
}

export default AboutUs
