import React from 'react'
import Hero from "../components/landing/Hero";
import About from "../components/landing/About";
import Chart from "../components/landing/Tokenomics";
import Projects from "../components/landing/Projects";
import Roadmap from "../components/landing/Roadmap";
import Security from "../components/landing/Security";
import Partners from "../components/landing/Partners";
import Faqs from "../components/landing/Faqs";
import Footer from "../components/landing/Footer";
import DigiPool from "../components/landing/DigiPool";

const Landing = () => {
  return (
    <>
    <Hero />
    <DigiPool />

    <div className="bg-gradient-to-tl from-gray-500 via-green-100/50 to-gray-400 ">
      <div className=" dark:bg-black">
        <About />
      </div>
    </div>
    <div className=" bg-gradient-to-tr from-gray-400 via-green-100/50 to-gray-500">
      <div className=" dark:bg-black">
        <Chart />
      </div>
    </div>
    <div className="bg-gradient-to-tl from-gray-500 via-green-100/50 to-gray-400 ">
      <div className=" dark:bg-black pt-[4rem] pb-8 ">
        <Projects />
      </div>
    </div>
    <div className="bg-gradient-to-tr from-gray-400 via-green-100/50 to-gray-500 ">
      <div className=" dark:bg-black">
        <Roadmap />
      </div>
    </div>
    <div className="bg-gradient-to-tl from-gray-500 via-green-100/50 to-gray-400 ">
      <div className=" dark:bg-black">
        <Security />
      </div>
    </div>
    <div className="bg-gradient-to-tr from-gray-400 via-green-100/50 to-gray-500 ">
      <div className=" dark:bg-black">
 
        <Partners />
      </div>
    </div>
    <div className="bg-gradient-to-tl from-gray-500 via-green-100/50 to-gray-400 ">
      <div className=" dark:bg-black">
        <Faqs />
      </div>
    </div>
    <div className="bg-gradient-to-tr from-gray-300 via-green-100/50 to-gray-400 ">
      <div className=" dark:bg-black">
    <Footer />
      </div>
    </div>
   
  </>
  )
}

export default Landing
