import { Button, Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react'
import { useState } from 'react'

export default function TeamInfo({ isOpen, setIsOpen, info, index }) {
    console.log("info", info)

    function open() {
        setIsOpen(true)
    }

    function close() {
        setIsOpen(false)
    }

    return (
        <>


            <Transition appear show={isOpen}>
                <Dialog as="div" className="relative z-10 focus:outline-none" onClose={close}>
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4">
                            <TransitionChild
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 transform-[scale(95%)]"
                                enterTo="opacity-100 transform-[scale(100%)]"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 transform-[scale(100%)]"
                                leaveTo="opacity-0 transform-[scale(95%)]"
                            >
                                <DialogPanel className="w-full max-w-md  bg-white/5  backdrop-blur-2xl border-2 bg-gradient-to-tr from-gray-500 via-green-100/50 to-gray-400 dark:from-gray-800 dark:to-gray-800
          rounded-[2rem] p-0 md:p-4 pt-10  pb-[3rem] dark:bg-gray-800 boxShadow">
                                    <DialogTitle as="h3" className="text-2xl tracking-wider font-medium flex items-center justify-between ">
                                        {info[index]?.name} <span className='cursor-pointer font-bold backdrop-blur-lg' onClick={(()=>{close()})}>x</span>
                                    </DialogTitle>
                                    <div className="fle flex-col space-y-4 mt-4 ">
                                        <div className='font-medium'> {info[index]?.designation}</div>
                                        <div> {info[index]?.description}</div>

                                    </div>
                                    <div className="mt-4">

                                    </div>
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}