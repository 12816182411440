"use client"
import React from "react";
import {Switch, cn} from "@nextui-org/react";
import {MoonIcon} from "./MoonIcon";
import {SunIcon} from "./SunIcon";
import { useTheme } from "next-themes"
import { useState, useEffect } from "react";


export default function App() {
  
  const [isLightMode, setIsLightMode] = useState(true);
  const { theme, setTheme } = useTheme();

  useEffect(() => {
    // Set initial theme based on the detected theme from next-themes
    setIsLightMode(theme === "light");
  });

  const toggleTheme = () => {
    setIsLightMode((prevMode) => !prevMode);
    setTheme(isLightMode ? "dark" : "light");
  };

  return (
    <>
  
    <Switch
      defaultSelected
      size="lg"
      color="primary"
      classNames={{
       
        wrapper: "p-0 h-8 w-[4rem] overflow-visible bg-gray-600",
        thumb: cn("w-7 h-7 border-2 shadow-lg ",
          "group-data-[hover=true]:border-primary ",
          //selected
          "group-data-[selected=true]:ml-[2.5rem]  bg-gray-100",
          // pressed
          "group-data-[pressed=true]:w-7",
          "group-data-[selected]:group-data-[pressed]:ml-4",
        ),
        
      }}
      onClick={toggleTheme}
      thumbIcon={({ isSelected, className }) =>
        isSelected ? (
          
          <SunIcon className={className} /> 
          
        ) : (
          
          <MoonIcon className={className} /> 
          
        )
      }
      >
      
    </Switch>
    
      </>
  );
}
