import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { FADE_DOWN_ANIMATION_VARIANTS } from "../../lib/framer";
import about1 from "./../../assets/about1.png";
import about2 from "./../../assets/about2.png";
import about3 from "./../../assets/about3.png";

const aboutData = [
  {
    image: about1,
    title: "Our Investment Philosophy Unveiled",
    content:
      "We prioritize strategic analysis and diligent research to achieve long-term growth for our investors through blockchain and cryptocurrencies.",
  },
  {
    image: about2,
    title: "At our core: expertise in cryptocurrency.",
    content:
      "Our seasoned team of crypto experts drives our success through in-depth knowledge and innovation.",
  },
  {
    image: about3,
    title: "Transparency and Trust: Our Core Values",
    content:
      "We believe in open communication, honesty, and integrity in all our dealings. It's how we build lasting relationships and earn the trust of our clients and partners.",
  },
];

function About() {
  const { ref, inView } = useInView({
    triggerOnce: false,
  });

  return (
    <div className="flex flex-col items-center justify-center h-full pt-[3rem] p-4 md:p-[7.5rem]  ">
      <motion.div
        ref={ref}
        initial="hidden"
        animate={inView ? "show" : "hidden"}
        viewport={{ once: true }}
        variants={{
          hidden: {},
          show: {
            transition: {
              staggerChildren: 0.5,
            },
          },
        }}
        className="border-2 border-gray-300 dark:border-gray-700 bg-gradient-to-tr from-slate-50 via-gray-300 to-stone-50 dark:from-gray-800 dark:to-gray-800
          rounded-[2rem] p-0 md:p-4 pt-10  pb-[3rem] dark:bg-gray-800 boxShadow"
      >
        <motion.h1
          variants={FADE_DOWN_ANIMATION_VARIANTS}
          className="text2 text-[1.6rem] md:text-[2.2rem] font-bold text-center text-heading dark:text-gray-300 "
        >
          Discover Our Story: <br />
          Unveiling the Essence of <br />
          Who We Are
        </motion.h1>

        <motion.div  className="grid grid-cols-1 gap-10  sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 mt-[4rem] mb-10 px-[2rem] ">
          {/* Map over aboutData to create grid items */}
          {aboutData.map((item) => (
            <motion.div key={item.title} variants={FADE_DOWN_ANIMATION_VARIANTS}  className="  border-transparent hover:border-brand  dark:hover:border-brand   border-2 bg-transparent rounded-[2rem] transition duration-200 ease-in-out p-6   cursor-pointer">
              <div className="flex flex-col items-center text-center md:items-start md:text-left   gap-6 space-x-0 ">
              <div className="w-[2.6em] h-[2.6em] rounded-full bg-gray-300 flex justify-center items-center">
                <img src={item.image} className="w-[2em] h-[2em] rounded-full" />
              </div>
                <h3 className="text2 text-heading dark:text-gray-300 text-[1.3rem] font-bold">{item.title}</h3>
              </div>
              <p className="mt-2 text1 text-para text-center md:text-start dark:text-gray-300">{item.content}</p>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>
    </div>
  );
}

export default About;
