import React, { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import "./progress.css"
const Progress = () => {
    const { ref, inView } = useInView({
        threshold: 0.1,
        triggerOnce: false,
      });

      const softCap = 47100;
      const hardCap = 500000;
    
     
    const per  = parseFloat(softCap/hardCap)*100

  return (
    <div class="flex flex-col gap-4   py-8 w-full items-center justify-center">
  <h2 className='font-semibold  text-heading text-2xl'>Fundraising Progress for DigiToken (DGFL)</h2>
  <div class="progress-container">
    <div className={`progress-bar ${inView ? 'pd' : ''}`}            style={{ width: `${inView?per:0}%` }}
  ref={ref}></div>
    <div class="progress-line softcap-line"></div>
    <div class="progress-line hardcap-line"></div>
  </div>
  <div class="marker-container">
    <p class=" market-raised font-semibold " >$471k Raised</p>
    <div class="  raised-amount font-semibold">$2.5 Million - Softcap</div>
    <p class="market-raised font-semibold" >$5 Million - Hardcap</p>
  </div>
</div>
  );
};

export default Progress;
